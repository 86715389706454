<template>
  <div>
    <h4 style="margin: 10px ; ">
        {{ titlename }}{{titletype}} · 队伍管理  
      </h4>
    <el-tabs
      v-if="type_id == 'wdl'"
      v-model="wdl_activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane label="大区赛" name="0"></el-tab-pane>
      <el-tab-pane label="总决赛" name="1"></el-tab-pane>
    </el-tabs>

    <div class="k-9sptci" v-loading="loading">
      <el-form
        :inline="true"
        class="k-9sptci-header"
        :model="searchForm"
        ref="searchForm"
      >
        <el-form-item class="team_status" label="UID" prop="uid">
          <el-input
            v-model="searchForm.uid"
            maxlength="8"
            clearable
            placeholder="领队、选手UID"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="type_id == 'wdl'"
          class="team_status"
          :label="wdl_activeName == 0 ? '队伍ID' : '门派ID'"
          prop="sect_id"
        >
          <el-input
            v-model="searchForm.sect_id"
            maxlength="6"
            clearable
            placeholder="ID"
          ></el-input>
        </el-form-item>
        <el-form-item v-else class="team_status" label="门派ID" prop="sect_id">
          <el-input
            v-model="searchForm.sect_id"
            maxlength="6"
            clearable
            placeholder="ID"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="team_status"
          v-if="type_id == 'wdl'"
          :label="wdl_activeName == 0 ? '队伍名称' : '门派名称'"
          prop="sect_name"
        >
          <el-input
            v-model="searchForm.sect_name"
            clearable
            placeholder="名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="team_status"
          v-else
          label="门派名称"
          prop="sect_name"
        >
          <el-input
            v-model="searchForm.sect_name"
            clearable
            placeholder="名称"
          ></el-input>
        </el-form-item>
        <el-form-item class="team_status" label="队伍状态" prop="team_status">
          <el-select
            v-model="searchForm.team_status"
            clearable
            placeholder="请选择"
          >
            <el-option value="0" label="满员"></el-option>
            <el-option value="1" label="缺领队"></el-option>
            <el-option value="2" label="缺选手"></el-option>
            <el-option value="3" label="缺人"></el-option>
          </el-select>
        </el-form-item>
        <!-- s4 之前状态 -->
        <el-form-item
          class="team_status"
          label="状态"
          prop="status"
          v-if="!match_type.includes('match_s')"
        >
          <el-select
            v-model="searchForm.status"
            placeholder="请选择"
            @change="searchClick"
          >
            <!-- <el-option value="-1" label="全部"></el-option> -->
            <el-option :value="0" label="正常"></el-option>
            <el-option :value="1" label="回收站"></el-option>
          </el-select>
        </el-form-item>
        <!-- s4 预选赛 -->
        <el-form-item
          class="team_status"
          label="参赛状态"
          prop="status"
          v-if="match_type.includes('match_s') && type_id === 'qualifier'"
        >
          <el-select
            v-model="searchForm.status"
            placeholder="请选择"
            @change="searchClick"
          >
            <el-option :value="0" label="参赛中"></el-option>
            <!-- is_black: number, //0 不是黑名单 1是黑名单 -->
            <el-option :value="3" label="黑名单"></el-option>
            <el-option :value="2" label="已弃赛"></el-option>
            <!-- is_sect_alliance: number, // 0加入门派联盟 1未加入门派联盟 -->
            <el-option :value="4" label="未加入联盟"></el-option>
            <el-option :value="1" label="其他情况"></el-option>
          </el-select>
        </el-form-item>
        <!-- s4 常规赛，总决赛 -->
        <el-form-item
          class="team_status"
          label="状态"
          prop="status"
          v-if="match_type.includes('match_s') && type_id !== 'qualifier'"
        >
          <el-select
            v-model="searchForm.status"
            placeholder="请选择"
            @change="searchClick"
          >
            <el-option :value="0" label="正常"></el-option>
            <el-option :value="3" label="黑名单"></el-option>
            <el-option v-if="Abandoning " :value="2" label="弃赛"></el-option>
          </el-select>
        </el-form-item>
        <div class="btn_container">
          <div class="btn_wraper">
            <el-form-item>
              <el-button type="success" @click="searchClick">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="exportTeamToExcel"
                >导出报名信息</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="exportTeamPortraitToExcel"
                >导出头像信息</el-button
              >
            </el-form-item>
            <perContainer :perm="`match:save`">
              <el-form-item>
                <el-button type="primary" @click="add">新增</el-button>
              </el-form-item>
            </perContainer>
          </div>

          <div class="btn_wraper btn_wraper_bottom" v-if="match_id">
            <el-form-item>
              <importButton
                :import-url="`/admin/match/new_match/import_sect_member`"
                :status-url="`/admin/match/new_match/import_sect_member_status`"
                :data="{
                  match_id: match_id,
                  season_id: season_id,
                  type_id: type_id,
                }"
                @refushList="searchClick()"
              ></importButton>
            </el-form-item>
            <div>
              <div>
                <div style="display: flex">
                  <importHistoryButton
                    :history_url="`/admin/match/new_match/import_sect_member_history`"
                    import_template="import_member_team"
                    :data="{
                      match_id: match_id,
                      season_id: season_id,
                      type_id: type_id,
                    }"
                  ></importHistoryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <el-table :data="dataTable" style="margin-top: 20px" border>
        <el-table-column
          v-if="type_id == 'wdl'"
          prop="sect_id"
          :label="wdl_activeName == 0 ? '队伍ID' : '门派ID'"
          width="200"
        >
        </el-table-column>
        <el-table-column v-else prop="sect_id" label="门派ID" width="200">
        </el-table-column>
        <el-table-column
          v-if="type_id == 'wdl'"
          prop="sect_name"
          :label="wdl_activeName == 0 ? '队伍名称' : '门派名称'"
          width="200"
        >
        </el-table-column>
        <el-table-column v-else prop="sect_name" label="门派名称" width="200">
        </el-table-column>
        <!-- s4之前 -->
        <div v-if="!match_type.includes('match_s')">
          <template v-for="item in dataList">
            <el-table-column
              v-if="
                item.prop.indexOf('member') == 0 || item.prop == 'alternate'
              "
              :key="item._id"
              :prop="item.prop"
              :type="item.type"
              :label="item.label"
              :width="item.width"
              :align="item.align || 'left'"
              :formatter="item.formatter"
            >
              <template slot-scope="scope">
                <div
                  v-if="item.formatter(scope.row) == null"
                  style="color: red; width: 100%"
                >
                  暂无
                </div>
                <div v-else style="width: 100%">
                  {{ item.formatter(scope.row) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'team_status'"
              :key="item._id"
              :prop="item.prop"
              :type="item.type"
              :label="item.label"
              :width="item.width"
              :align="item.align || 'left'"
              :formatter="item.formatter"
            >
              <template slot-scope="scope">
                <div
                  v-if="item.formatter(scope.row) != '满员'"
                  style="color: red; width: 100%"
                >
                  {{ item.formatter(scope.row) }}
                </div>
                <div v-else style="width: 100%">
                  {{ item.formatter(scope.row) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="item._id"
              :prop="item.prop"
              :type="item.type"
              :label="item.label"
              :width="item.width"
              :align="item.align || 'left'"
              :formatter="item.formatter"
            >
            </el-table-column>
          </template>
        </div>
        <!-- s4 -->
        <div v-if="match_type.includes('match_s')">
          <template v-for="item in dataLists4">
            <el-table-column
              v-if="
                item.prop.indexOf('member') == 0 || item.prop == 'alternate'
              "
              :key="item._id"
              :prop="item.prop"
              :type="item.type"
              :label="item.label"
              :width="item.width"
              :align="item.align || 'left'"
              :formatter="item.formatter"
            >
              <template slot-scope="scope">
                <div
                  v-if="item.formatter(scope.row) == null"
                  style="color: red; width: 100%"
                >
                  暂无
                </div>
                <div v-else style="width: 100%">
                  {{ item.formatter(scope.row) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'team_status'"
              :key="item._id"
              :prop="item.prop"
              :type="item.type"
              :label="item.label"
              :width="item.width"
              :align="item.align || 'left'"
              :formatter="item.formatter"
            >
              <template slot-scope="scope">
                <div
                  v-if="item.formatter(scope.row) != '满员'"
                  style="color: red; width: 100%"
                >
                  {{ item.formatter(scope.row) }}
                </div>
                <div v-else style="width: 100%">
                  {{ item.formatter(scope.row) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="item._id"
              :prop="item.prop"
              :type="item.type"
              :label="item.label"
              :width="item.width"
              :align="item.align || 'left'"
              :formatter="item.formatter"
            >
            </el-table-column>
          </template>
          <!-- 赛事状态 -->
          <el-table-column label="赛事状态" width="200">
            <template slot-scope="scope">
              <div class="EventStatus">
                <div
                  class="EventStatus_item"
                  v-if="
                    scope.row.status === 0 &&
                    scope.row.is_black !== 1 &&
                    scope.row.status !== 2
                  "
                >
                  参赛中
                </div>
                <div class="EventStatus_item" v-if="scope.row.is_black === 1">
                  黑名单
                </div>
                <div class="EventStatus_item" v-if="scope.row.status === 2">
                  已弃赛
                </div>
                <div
                  class="EventStatus_item"
                  v-if="scope.row.is_sect_alliance === 1"
                >
                  未加入门派联盟
                </div>
                <div v-if="scope.row.status === 1">其他情况</div>
              </div>
            </template>
          </el-table-column>
        </div>
        <!-- 操作 -->
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <perButton
              :perm="`match:save`"
              @click.native.stop="handleEdit(scope.row)"
              >编辑队伍</perButton
            >
            <popconfirmButton
              v-if="scope.row.status == 0"
              :perm="`match:save`"
              content="确定将该门派状态更为其他状态？"
              text="删除"
              @onConfirm="handleDelete(scope.row)"
              style="margin-left: 10px"
            ></popconfirmButton>
            <popconfirmButton
              v-else
              :perm="`match:save`"
              content="是否确认恢复？"
              text="恢复"
              @onConfirm="handleRecovery(scope.row)"
              style="margin-left: 10px"
            ></popconfirmButton>
          </template>
        </el-table-column>
      </el-table>
      <div class="k-9sptci-footer">
        <el-pagination
          @current-change="handleQuery"
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="pageSize"
          :current-page.sync="pager.pageNum"
          layout="sizes, prev, pager, next"
          :total="pager.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { matchTypeMap,matchType } from "./public.js/toolbarOptions";
import perButton from "@/components/perm/perButton";
import importButton from "../components/importButton";
import importHistoryButton from "../components/importHistoryButton";
export default {
  name: "team_list",
  components: {
    perButton,
    importButton,
    importHistoryButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    titlename() {
      return matchTypeMap[this.match_type] || "";
    },
     titletype() {
      return matchType[this.type_id] || "";
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        this.type_id = this.$route.query.type_id;
        // this.handleQuery();
      },
      immediate: true,
    },
  },

  data() {
    let years = [];
    for (
      let i = new Date().getFullYear() + 1;
      i >= new Date().getFullYear() - 10;
      i--
    ) {
      years.push(i);
    }

    let membersColumn = [];
    for (let i = 0; i < 9; i++) {
      membersColumn.push({
        prop: `member${i + 1}`,
        label: `第${i + 1}场`,
        width: 100,
        formatter: (row) => {
          let member = row.members.find((r) => r.index == i);
          if (!member) {
            return null;
          }

          return member.name + "|" + member.uid || "";
        },
      });
    }

    return {
      Abandoning:false,//弃赛
      wdl_activeName: 0,
      match_type: "",
      ids: [],
      match_id: "",
      type_id: "",
      season_id: "",
      loading: false,
      pageSize: 10,
      years: years,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        uid: "",
        sect_id: "",
        sect_name: "",
        team_status: "",
        status: 0,
      },
      dataTable: [],
      dataList: [
        {
          prop: "leader_name",
          label: "领队",
          width: 300,
          formatter: (row) => {
            if (row.leader_uid && row.leader_uid.length) {
              return row.leader_name + "|" + row.leader_uid;
            }

            return "暂无";
          },
        },
        // ...membersColumn,
        // {
        //   prop: "alternate",
        //   label: "替补",
        //   width: 150,
        //   formatter: (row) => {
        //     let member = row.alternate[0];
        //     if (!member) {
        //       return null;
        //     }
        //
        //     return member.name || '';
        //   }
        // },
        {
          prop: "createTime",
          label: "创建时间",
          width: 150,
          formatter: (row) => {
            return new Date(row.createTime).format("yyyy-MM-dd hh:mm:ss");
          },
        },
        {
          prop: "team_status",
          label: "队伍状态",
          width: 150,
          align: "center",
          formatter: (row) => {
            if (row.team_status == 0) {
              return "满员";
            } else if (row.team_status == 1) {
              return "缺领队";
            } else if (row.team_status == 2) {
              return "缺选手";
            } else if (row.team_status == 3) {
              return "缺人";
            } else {
              return "";
            }
          },
        },
        {
          prop: "status",
          label: "赛事状态",
          width: 150,
          align: "center",
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "正常";
              case 1:
                return "回收站";
              case 2:
                return "主动弃赛";
              default:
                return "";
            }
          },
        },
      ],
      dataLists4: [
        {
          prop: "leader_name",
          label: "领队",
          width: 300,
          formatter: (row) => {
            if (row.leader_uid && row.leader_uid.length) {
              return row.leader_name + "|" + row.leader_uid;
            }

            return "暂无";
          },
        },
        {
          prop: "createTime",
          label: "创建时间",
          width: 150,
          formatter: (row) => {
            return new Date(row.createTime).format("yyyy-MM-dd hh:mm:ss");
          },
        },
        {
          prop: "team_status",
          label: "队伍状态",
          width: 150,
          align: "center",
          formatter: (row) => {
            if (row.team_status == 0) {
              return "满员";
            } else if (row.team_status == 1) {
              return "缺领队";
            } else if (row.team_status == 2) {
              return "缺选手";
            } else if (row.team_status == 3) {
              return "缺人";
            } else {
              return "";
            }
          },
        },
      ],
    };
  },
  methods: {
    //wdl选择大区赛还是总决赛
    handleClick(tab) {
      this.handleQuery();
    },
    add() {
      this.removeTabByPath(`/match/subject/add_team`);
      this.removeTabByPath(`/match/subject/edit_team`);
      if (this.type_id === "qualifier") {
        this.$router.push({
          path: `/match/info/info_s1/edit_team`,
          query: {
            match_id: this.match_id,
            type_id: this.type_id,
            view_type: "add",
            match_type: this.match_type,
            season_id: this.season_id,
          },
        });
      } else {
        this.$router.push({
          path: `/match/subject/edit_team`,
          query: {
            match_id: this.match_id,
            type_id: this.type_id,
            season_id: this.season_id,
            view_type: "add",
            match_type: this.$route.query.match_type,
          },
        });
      }
    },

    handleEdit(row) {
      this.removeTabByPath(`/match/subject/add_team`);
      this.removeTabByPath(`/match/subject/edit_team`);
      // match_id为赛事id,  _id为当前队伍id,
      if (this.type_id === "qualifier") {
        console.log(111, row._id);
        this.$router.push({
          path: `/match/info/info_s1/edit_team`,
          query: {
            _id: row._id,
            match_id: this.match_id,
            type_id: this.type_id,
            view_type: "edit",
            match_type: this.match_type,
            season_id: this.season_id,
          },
        });
      } else {
        this.$router.push({
          path: `/match/subject/edit_team`,
          query: {
            _id: row._id,
            match_id: this.match_id,
            type_id: this.type_id,
            season_id: this.season_id,
            view_type: "edit",
            match_type: this.$route.query.match_type,
          },
        });
      }
    },
    async handleDelete(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match_subject.new_delete_team({
        _id: row._id,
      });
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      // row.status = data.status;
      this.handleQuery();
    },
    async handleRecovery(row) {
      this.loading = true;
      let { data, errorCode } =
        await this.$http.match_subject.new_recovery_team({ _id: row._id });
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "恢复成功",
      });

      // row.status = data.status;
      this.handleQuery();
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async exportTeamToExcel() {
      var teamParams = {
        match_id: this.match_id,
        season_id: this.season_id,
      };
      this.$http.download({
        url: "/admin/match/new_match/export_TeamToExcel",
        method: "post",
        data: teamParams,
      });
    },
    // 导入s1队伍信息
    async exportTeamPortraitToExcel() {
      var teamParamsPortrait = {
        match_id: this.match_id,
        season_id: this.season_id,
      };
      this.$http.download({
        url: "/admin/match/new_match/export_TeamPortrait",
        method: "post",
        data: teamParamsPortrait,
      });
    },

    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      let { pageNum } = this.pager;
      let searchForm = this.searchForm;
      console.log("111", searchForm);

      let params = {
        ...this.searchForm,
        match_id: this.match_id,
        season_id: this.season_id,
        pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.status === 3) {
        delete params.status;
        params.is_black = 1;
      } else if (this.searchForm.status === 4) {
        delete params.status;
        params.is_sect_alliance = 1;
      } else {
        params = {
          ...this.searchForm,
          match_id: this.match_id,
          season_id: this.season_id,
          pageNum,
          pageSize: this.pageSize,
        };
      }
      const { data, errorCode } = await this.$http.match_subject.new_teams(
        params
      );
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      //判断弃赛的状态
     this.Abandoning = data.data.some(item => item.status === 2);

      this.pager = data.pager;
    },
    onTeamChanged(data) {
      let current = this.dataTable.find((r) => r._id == data._id);
      if (current) {
        current.members = data.members;
        current.alternate = data.alternate;
        current.leader_uid = data.leader_uid;
        current.leader_name = data.leader_name;
        current.leader_avatar = data.leader_avatar;
        current.team_status = data.team_status;
        current.status = data.status;
      } else {
        this.pager.pageNum = 1;
        this.handleQuery();
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  activated() {
    this.match_id = this.$route.query.match_id;
    this.season_id = this.$route.query.season_id;
    this.type_id = this.$route.query.type_id;
    this.match_type = this.$route.query.match_type;
    this.handleQuery();
  },
  async created() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.$bus.$off("onTeamChanged", this.onTeamChanged);
    this.$bus.$on("onTeamChanged", this.onTeamChanged);
  },
  destroyed() {
    this.$bus.$off("onTeamChanged", this.onTeamChanged);
  },
};
</script>
<style lang="less">
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
.team_status {
  width: 160px;
}
.btn_wraper {
  display: flex;
}
.btn_wraper_bottom {
  margin-top: 10px;
}
.btn_container {
  display: flex;
  flex-direction: column;
}
.EventStatus {
  display: flex;
  flex-wrap: wrap;
}
.EventStatus_item {
  margin-right: 8px;
}
</style>
